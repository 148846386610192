import classnames from 'classnames';

import { format } from '@/helpers/util/dateUtil';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import { isDefined } from './utils';

/**
 * Check if this month is within the start and end range
 */
const isInRange = (idx: number, rangeStart: number, rangeEnd: number) =>
  isDefined(rangeStart) && isDefined(rangeEnd) && idx > rangeStart && idx < rangeEnd;

/**
 * Check if this month is the same as the one being hovered/focussed
 */
const isHover = (idx: number, hoverIdx: number) => hoverIdx === idx;

/**
 * Check if this is the hovered month, but also if its the left side of the range
 */
const isStartHover = (idx: number, rangeStart: number, rangeEnd: number, hoverIdx: number) =>
  (hoverIdx === idx && !isDefined(rangeStart)) ||
  (hoverIdx === idx && idx < rangeStart) ||
  (hoverIdx === idx && hoverIdx > rangeEnd && isDefined(rangeEnd));

/**
 * Check if this is the hovered month, but also if its the right side of the range
 */
const isEndHover = (idx: number, rangeStart: number, rangeEnd: number, hoverIdx: number) =>
  hoverIdx === idx && hoverIdx > rangeStart && isDefined(rangeStart) && !isDefined(rangeEnd);

/**
 * Is this month in between the start range and the current hover position
 * This is the highlighted state of months when we are mid-selection
 */
const isActiveHover = (idx: number, rangeStart: number, rangeEnd: number, hoverIdx: number) => {
  if (isDefined(rangeStart) && isDefined(rangeEnd)) {
    return false;
  }

  if (isDefined(rangeStart)) {
    return hoverIdx > rangeStart && idx > rangeStart && idx < hoverIdx;
  }

  return false;
};

type Props = {
  applyNewFilterUI?: boolean;
  hoverIdx?: number;
  monthData: {
    date?: Record<string, unknown>;
    disabled?: boolean;
    hideable?: boolean;
    idx: number;
  };
  onMonthClick: () => void;
  onMonthHover: () => void;
  rangeEnd?: number;
  rangeStart?: number;
};

const MonthButton = ({
  applyNewFilterUI,
  hoverIdx,
  monthData,
  onMonthClick,
  onMonthHover,
  rangeEnd,
  rangeStart,
}: Props) => {
  const { date, disabled, hideable, idx } = monthData;
  const isInRangeFlag = isInRange(idx, rangeStart, rangeEnd);
  const isRangeEnd = idx === rangeEnd;
  const isRangeStart = idx === rangeStart;
  const isSelected = isInRangeFlag || isRangeStart || isRangeEnd;

  const cssClasses = classnames({
    '-activeHover': isActiveHover(idx, rangeStart, rangeEnd, hoverIdx),
    '-disabled': disabled,
    '-endHover': isEndHover(idx, rangeStart, rangeEnd, hoverIdx),
    '-hideable': hideable,
    '-hoverDate': isHover(idx, hoverIdx),
    '-inRange': isInRangeFlag,
    '-newFilter': applyNewFilterUI,
    '-rangeEnd': isRangeEnd,
    '-rangeStart': isRangeStart,
    '-selectedMonth': !rangeEnd && rangeStart === idx && hoverIdx <= idx,
    '-startHover': isStartHover(idx, rangeStart, rangeEnd, hoverIdx),
    DatePicker__month: true,
  });

  const getAriaLabel = () => {
    let result = format(date, 'MMM yyyy');
    if (isSelected) result += ' selected';
    return result;
  };

  const onClick = () => {
    if (disabled) {
      return;
    }
    onMonthClick(idx);
  };

  const focusMonth = () => {
    if (disabled) {
      return;
    }
    onMonthHover(idx);
  };

  const defocusMonth = () => {
    if (disabled) {
      return;
    }
    onMonthHover(null);
  };

  const onKeyDown = useOnKeyDown((e) => {
    e.preventDefault();
    onClick(e);
  });

  return (
    <button
      aria-label={getAriaLabel(isSelected)}
      className={cssClasses}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseOut={defocusMonth}
      onMouseOver={focusMonth}
      type="button"
    >
      <span>{format(date, 'MMM')}</span>
    </button>
  );
};

export default MonthButton;
