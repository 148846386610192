import { useState } from 'react';

import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useEventListener } from 'usehooks-ts';

import FilterOption from '@publicImages/svg/filters.svg?static';

import UIResource from '@/components/UIResource';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import styles from './SearchAgainButton.module.scss';

export const isGoesOutOfView = (targetSelector: string) => {
  if (document.documentElement.scrollTop) {
    const rect = document.querySelector(targetSelector)?.getBoundingClientRect();
    return !!rect && rect.top < 0;
  }
  return false;
};

type TProps = {
  className?: string;
  onChangeVisibility?: (isVisible: boolean) => void;
  onClick?: () => void;
  targetSelector: string;
};

const SearchAgainButton = ({ className, onChangeVisibility, onClick, targetSelector }: TProps) => {
  const [isVisible, setIisVisible] = useState<boolean>(false);

  useEventListener(
    'scroll',
    throttle(() => {
      const next = isGoesOutOfView(targetSelector);
      if (next !== isVisible) {
        setIisVisible(next);
        onChangeVisibility?.(next);
      }
    }, 150),
  );

  const onSearch = () => {
    setIisVisible(false);
    onChangeVisibility?.(false);
    onClick?.();
  };

  const onKeyDown = useOnKeyDown();

  return (
    isVisible && (
      <div className={cn(styles.root, className)}>
        <button className={styles.button} onClick={onSearch} onKeyDown={onKeyDown}>
          <FilterOption />
          <span className={styles.text}>
            <UIResource id="ChooseVoyage.SearchAgain.button" />
          </span>
        </button>
      </div>
    )
  );
};

export default SearchAgainButton;
