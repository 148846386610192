import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';

import useGetRefinementPopoverProps from '@/components/ResultRefinements/RefinementPopover/useGetRefinementPopoverProps';
import { selectDestinationsPillLabel } from '@/ducks/filters/labels/destinations';
import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY, type FiltersTypes } from '@/ducks/filters/types';
import { useAppDispatch, useAppSelector } from '@/store';
import { type NullablePartial } from '@/types/common';

import DestinationRefinement from './index';
import { DestinationType, type TSelectedRegionInfo } from './types';

type UseRefinementFiltersProps = {
  id?: string;
  isMobile: boolean;
  onChangeStatus: (isOpen: boolean) => void;
};

const useDestinationRefinementPopover = ({ isMobile, onChangeStatus }: UseRefinementFiltersProps) => {
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLElement | null>(null);
  const searchParams = useSearchParams()!;
  const defaultPillLabel = useAppSelector((state) => selectDestinationsPillLabel(state, searchParams));
  const [pillLabel, setPillLabel] = useState<string>(defaultPillLabel);

  useEffect(() => setPillLabel(defaultPillLabel), [defaultPillLabel]);

  const popoverProps = useGetRefinementPopoverProps({
    buttonRef,
    focusTrapDisableEvents: ['x-arrows', 'y-arrows'],
    isInline: isMobile,
    isMobile,
    onChangeStatus,
    outsidePress: !isMobile,
    placement: 'bottom-start',
  });

  const onComplete = (selectedRegionInfo: TSelectedRegionInfo, isResetVoyages?: boolean) => {
    const { destinationType, itemsIds, regionsIds } = selectedRegionInfo || {};
    const filters: NullablePartial<FiltersTypes> = {
      [FILTER_KEY.destPackages]: destinationType === DestinationType.ITINERARIES ? (itemsIds ?? []) : [],
      [FILTER_KEY.destPackagesRegions]: destinationType === DestinationType.ITINERARIES ? (regionsIds ?? []) : [],
      [FILTER_KEY.destPorts]: destinationType === DestinationType.PORTS_OF_CALL ? (itemsIds ?? []) : [],
      [FILTER_KEY.destPortsRegions]: destinationType === DestinationType.PORTS_OF_CALL ? (regionsIds ?? []) : [],
      ...(isResetVoyages ? { [FILTER_KEY.voyageIds]: null } : undefined),
    };
    const searchParams = setFilters(filters);
    const label = dispatch((_, getState) => selectDestinationsPillLabel(getState(), searchParams));
    setPillLabel(label);
  };

  const popover = useMemo(
    () => (
      <DestinationRefinement
        isMobile={isMobile}
        key="DestinationRefinementKey"
        onComplete={onComplete}
        popoverProps={popoverProps}
      />
    ),
    [isMobile, popoverProps],
  );

  return {
    ...popoverProps,
    buttonProps: popoverProps.getReferenceProps(/* { className: ... } */),
    pillLabel,
    popover,
  };
};

export default useDestinationRefinementPopover;
