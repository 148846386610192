import cn from 'classnames';

import CaretRight from '@publicImages/svg/caret-right.svg?icon';

import styles from './ArrowButtonToggleAnimated.module.scss';

const ArrowButtonToggleAnimated = ({ direction }: { direction: 'down' | 'up' }) => {
  return <CaretRight className={cn(styles.root, styles[direction])} />;
};

export default ArrowButtonToggleAnimated;
