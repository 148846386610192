import isEmpty from 'lodash/isEmpty';
import { connect, type ConnectedProps } from 'react-redux';

import type { RootState } from '@/store';

import FormCheck from '@/components/elements/FormCheck';
import UIResource from '@/components/UIResource';
import { selectLookup } from '@/ducks/common/selectors';
import { selectShipsLabel } from '@/ducks/filters/labels/ships';
import { selectShipList } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectMainSailings } from '@/ducks/pages/chooseVoyage/selectors';

import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

type Props = ConnectedProps<typeof connector> & {
  closeFilterModal: () => void;
};

class ShipFilter extends BaseFilter<Props> {
  onSelection = (ship: { id?: string }) => {
    const { selectedships } = this.props;
    const selected = selectedships?.find((currShip) => currShip.id === ship.id);

    if (!selected) {
      setFilter.ships([...selectedships, ship]);
      return;
    }
    const shipList = selectedships.filter((secShip) => secShip.id !== ship.id);

    setFilter.ships(shipList);
  };

  render() {
    const { closeFilterModal, isOpenFilterSection, label, mainSailings, metaShipList, selectedships } = this.props;
    return (
      <AdvancedFilterHOC
        className="ShipFilter__main"
        closeFilterModal={closeFilterModal}
        defaultValue={label}
        filterName="AdvancedFilter.Ship"
        isOpen={isOpenFilterSection}
        toggleFilterSectionState={this.toggleFilterSectionState}
      >
        <div className="action ship-list">
          {!isEmpty(metaShipList) &&
            metaShipList.map((ship) => (
              <FormCheck
                aria-label={`${ship?.name} ${ship?.inaugurationDate}`}
                checked={!isEmpty(selectedships?.find((selecShip) => selecShip?.id === ship?.id))}
                disabled={!mainSailings.find((sailing) => sailing?.ship?.code === ship?.id)}
                id={`ship_${ship?.id}`}
                inputTabIndex={0}
                key={ship?.id}
                labelProps={{ 'aria-hidden': true }}
                name={`ship_${ship?.id}`}
                onChange={() => {
                  this.onSelection(ship);
                }}
                role="presentation"
                tabIndex={-1}
                type="checkbox"
              >
                <p className="name">{ship?.name}</p>
                <p className="year">{ship?.inaugurationDate}</p>
              </FormCheck>
            ))}
        </div>
        <p className="info">
          <UIResource id="AdvancedFilter.ship.info" />
        </p>
      </AdvancedFilterHOC>
    );
  }
}

const connector = connect((state: RootState) => ({
  label: selectShipsLabel(state),
  mainSailings: selectMainSailings(state),
  metaShipList: selectLookup(state)?.ships ?? [],
  selectedships: selectShipList(state),
}));

export default connector(ShipFilter);
