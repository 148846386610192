import { createSelector } from '@reduxjs/toolkit';
import intersection from 'lodash/intersection';

import {
  selectDestinationsFilterData,
  selectDestinationType,
} from '@/components/ResultRefinements/DestinationRefinement/selectors';
import { type DestinationRegion, DestinationType } from '@/components/ResultRefinements/DestinationRefinement/types';
import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { type RootState } from '@/store';

import { FILTER_KEY } from '../types';
import { getSearchParamsValue, toStringArray } from '../utils';

const selectSelectedRegions = createSelector(
  selectDestinationsFilterData,
  selectDestinationType,
  (_: RootState, searchParams: URLSearchParams) => searchParams,
  (data, destinationType, searchParams) => {
    const selectedRegions = data[destinationType].filter((region) => region.selectedItemsIds.length > 0);
    const regionsIds = toStringArray(getSearchParamsValue(FILTER_KEY.destPortsRegions, searchParams));

    return regionsIds.length > 0 && destinationType === DestinationType.PORTS_OF_CALL
      ? selectedRegions.reduce<typeof selectedRegions>(
          (rawSelectedRegions, region) =>
            regionsIds.includes(region.id) ? [...rawSelectedRegions, region] : rawSelectedRegions,
          [],
        )
      : selectedRegions;
  },
);

export const selectDestinationsLabel = createSelector(
  selectDestinationsFilterData,
  selectResources,
  selectDestinationType,
  (state: RootState, searchParams: URLSearchParams) => selectSelectedRegions(state, searchParams),
  (data, resources, destinationType, selectedRegions) => {
    const allRegions = selectedRegions.length === 0 || selectedRegions.length === data[destinationType].length;

    if (allRegions) {
      return combineUIResource(resources, 'ChooseVoyage.Results.AllDestinations');
    }

    let labels = selectedRegions.reduce<string[]>(
      (labels, region) => (region.label ? [...labels, region.label] : labels),
      [],
    );

    if (labels.length > 0) {
      labels = [labels.slice(0, -1).join(', '), labels[labels.length - 1]!].filter(Boolean);
    }

    return labels.join(' & ');
  },
);

export const LABEL_MAX_SEGMENTS = 2;

export const selectDestinationsPillLabel = createSelector(
  selectDestinationsFilterData,
  selectResources,
  (_: RootState, searchParams: URLSearchParams) => searchParams,
  (data, resources, searchParams) => {
    const packagesIds = toStringArray(getSearchParamsValue(FILTER_KEY.destPackages, searchParams));
    const packagesRegionIds = toStringArray(getSearchParamsValue(FILTER_KEY.destPackagesRegions, searchParams));
    const portsIds = toStringArray(getSearchParamsValue(FILTER_KEY.destPorts, searchParams));
    const portsRegionIds = toStringArray(getSearchParamsValue(FILTER_KEY.destPortsRegions, searchParams));

    const destinationType =
      portsIds.length || portsRegionIds.length
        ? DestinationType.PORTS_OF_CALL
        : packagesIds.length || packagesRegionIds.length
          ? DestinationType.ITINERARIES
          : undefined;
    if (destinationType) {
      const regions: DestinationRegion[] = data[destinationType];
      const itemsIds = destinationType === DestinationType.PORTS_OF_CALL ? portsIds : packagesIds;
      const regionsIds = destinationType === DestinationType.PORTS_OF_CALL ? portsRegionIds : packagesRegionIds;
      const asItems = !!itemsIds.length;
      const labels = [] as string[];
      for (const region of regions) {
        const { allItemsIds, id, label } = region;
        if (asItems) {
          const count = intersection(allItemsIds, itemsIds).length;
          if (count) labels.push(count === allItemsIds.length ? label : `${label} (${count})`);
        } else {
          if (regionsIds.includes(id)) labels.push(label);
        }
        if (labels.length >= LABEL_MAX_SEGMENTS) break;
      }
      if (labels.length) return labels.join(', ');
    }

    return combineUIResource(resources, 'Destination.filter.text');
  },
);
