import { createSelector } from '@reduxjs/toolkit';

import { selectDefaultCurrencyCode, selectSettings } from '@/ducks/common/selectors';

const selectDefaultParams = createSelector(
  selectSettings,
  selectDefaultCurrencyCode,
  (settings, defaultCurrencyCode) => {
    const { defaultCabinCount, defaultSailorCount } = settings || {};
    return { cabins: defaultCabinCount, currencyCode: defaultCurrencyCode, sailors: defaultSailorCount };
  },
);

export default selectDefaultParams;
