import { createSelector } from '@reduxjs/toolkit';

import { completeMonthLabel } from '@/helpers/util/dateUtil';
import { type TISODate } from '@/infra/types/common';

import { selectFromDate, selectToDate } from '../selectors';

export const makeTravelDatesLabel = (start: TISODate, end: TISODate): string => {
  const startMonth = completeMonthLabel(start);
  const endMonth = completeMonthLabel(end);
  return startMonth && endMonth ? [startMonth, endMonth].join(' - ') : '';
};

export const selectTravelDatesLabel = createSelector(selectFromDate, selectToDate, makeTravelDatesLabel);
