import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { type TUrlParams } from '@/infra/types/common';
import { useAppStore } from '@/store';

import { useAppDispatch } from '../';
import { type TFetchableResult } from '../fetchableParts/types';
import chooseRelevant from './chooseRelevant';
import selectDefaultParams from './selectDefaultParams';
import { prefillAll, selectPrefillInfo } from './stuff';

const usePrefill = (fetched: TFetchableResult, name: string, urlParams?: TUrlParams): void => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const store = useAppStore();

  const _ = useMemo(() => {
    const prefillInfo = selectPrefillInfo(store.getState());
    const defaultParams = selectDefaultParams(store.getState());
    const { relevant, versions } = chooseRelevant(fetched, prefillInfo, defaultParams) ?? {};
    if (relevant) {
      dispatch(
        prefillAll({ fetched: relevant!, prefillInfo: versions!, urlParams: searchParamsAsObject(searchParams) }, name),
      );
    }
    return undefined;
  }, [urlParams]);
};

export default usePrefill;
