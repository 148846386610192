import classNames from 'classnames';

import type { TFiltersDuration } from '@/infra/types/common/filters';

import FormCheck from '@/components/elements/FormCheck';
import UIResource from '@/components/UIResource';
import { imitateClickOnKeyEvent } from '@/hooks/useOnKeyDown';

import LuggageAnimation from '../Animations/LuggageAnimation';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

const getMaxDuration = (durations: TFiltersDuration[] = []) => {
  if (durations.length === 0) {
    return 'large';
  }
  const duration = durations.reduce((a, b) => (a.min > b.min ? a : b));
  return duration.animation;
};

type Props = {
  closeFilterModal: () => void;
  defaultAnimation?: string;
  durations: TFiltersDuration[];
  filteredDurationOptions: TFiltersDuration[];
  getDurationLabel: () => string;
  isOpenFilterSection?: boolean;
  isWeekendDisabled: boolean;
  label: React.ReactNode;
  onDurationChange: () => void;
  onWeekendChange: () => void;
  weekend: boolean;
};

class DurationFilter extends BaseFilter<Props> {
  renderMainContent = () => {
    const {
      defaultAnimation = 'large',
      durations,
      filteredDurationOptions,
      getDurationLabel,
      isWeekendDisabled,
      onDurationChange,
      onWeekendChange,
      weekend,
    } = this.props;

    return (
      <>
        <div className="img">
          <LuggageAnimation defaultDuration={defaultAnimation} selectedDuration={getMaxDuration(durations)} />
        </div>
        <div className="action">
          <div className="AdvancedRefinement__nights">
            {filteredDurationOptions &&
              filteredDurationOptions.map((duration) => {
                const active = durations?.find((d) => d.min === duration.min);

                const durationClassNames = classNames('AdvancedRefinement__noofdays', {
                  active,
                });
                return (
                  <div
                    aria-checked={!!active}
                    className={durationClassNames}
                    key={duration}
                    onClick={() => onDurationChange(duration, true)}
                    onKeyDown={imitateClickOnKeyEvent}
                    role="checkbox"
                    tabIndex={0}
                  >
                    <span>{getDurationLabel(duration)}</span>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="AdvancedRefinement__weekend">
          <FormCheck
            checked={weekend}
            className="weekend-form-check"
            disabled={!weekend && isWeekendDisabled}
            id="weekendOnly"
            inputTabIndex={0}
            name="weekendOnly"
            onChange={onWeekendChange}
            role="presentation"
            tabIndex={-1}
            type="checkbox"
            value="weekend"
          >
            <UIResource id="AdvancedRefinement.weekendLabel" />
          </FormCheck>
        </div>
        <p className="subtitle">
          <UIResource id="AdvancedFilter.Duration.weekendLabel.subtitle" />
        </p>
      </>
    );
  };

  render() {
    const { closeFilterModal, isOpenFilterSection, label } = this.props;
    return (
      <AdvancedFilterHOC
        className="DurationFilter__main"
        closeFilterModal={closeFilterModal}
        defaultValue={label}
        filterName="AdvancedFilter.Duration"
        isOpen={isOpenFilterSection}
        toggleFilterSectionState={this.toggleFilterSectionState}
      >
        {this.renderMainContent()}
      </AdvancedFilterHOC>
    );
  }
}

export default DurationFilter;
