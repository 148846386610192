import { useCallback } from 'react';

import useOnKeyDown from '@/hooks/useOnKeyDown';

import Arrow from '../Icon/Arrow';
import MonthButton from './MonthButton';

const setFocusOnArrow = (event) => {
  const button = event.currentTarget;
  const isLeft = button.matches('._left');
  const parent = button.closest('.DatePicker');
  if (button) {
    setTimeout(() => {
      const buttons = parent.querySelectorAll('.YearGroup__arrow');
      buttons[buttons.length > 1 && !isLeft ? 1 : 0].focus();
    }, 0);
  }
};

type Props = {
  applyNewFilterUI?: boolean;
  hideBothArrows?: boolean;
  hideLeftArrow?: boolean;
  hideRightArrow?: boolean;
  hoverIdx?: number;
  isLeftArrowDisabled: boolean;
  isRightArrowDisabled: boolean;
  isShowHeader?: boolean;
  label: string;
  months: Array<{
    date: Record<string, unknown>;
    disabled?: boolean;
  }>;
  onLeftArrowClick: () => void;
  onMonthClick: () => void;
  onMonthHover: () => void;
  onRightArrowClick: () => void;
  rangeEnd?: number;
  rangeStart?: number;
  resetYears: () => void;
  yearIndex: number;
};

const YearGroup = ({
  applyNewFilterUI,
  hideBothArrows,
  hideLeftArrow,
  hideRightArrow,
  hoverIdx,
  isLeftArrowDisabled,
  isRightArrowDisabled,
  isShowHeader = true,
  label,
  months,
  onLeftArrowClick,
  onMonthClick,
  onMonthHover,
  onRightArrowClick,
  rangeEnd,
  rangeStart,
  resetYears,
  yearIndex,
}: Props) => {
  const onClick = useCallback(
    (event) => {
      const button = event.currentTarget;
      if (button) (button.matches('._left') ? onLeftArrowClick : onRightArrowClick)(+button.dataset.clickPayload);
      setFocusOnArrow(event);
    },
    [onLeftArrowClick, onRightArrowClick],
  );

  const onKeyDown = useOnKeyDown(onClick, { isDefaultPrevented: true });

  return (
    <div className="DatePicker__yearGroup">
      {isShowHeader && (
        <>
          <div className="datepickerHeader">Travel dates</div>
          <span className="resetMonthsSelected" onClick={() => resetYears()}>
            Reset
          </span>
        </>
      )}
      <div className="arrowsNYear">
        {!hideLeftArrow && !hideBothArrows && (
          <button
            aria-label="Move to previous year"
            className="YearGroup__arrow _left"
            data-click-payload={yearIndex}
            disabled={isLeftArrowDisabled}
            onClick={onClick}
            onKeyDown={onKeyDown}
          >
            <Arrow />
          </button>
        )}
        <h2>{label}</h2>
        {!hideRightArrow && !hideBothArrows && (
          <button
            aria-label="Move to next year"
            className="YearGroup__arrow _right"
            data-click-payload={yearIndex - 1}
            disabled={isRightArrowDisabled}
            onClick={onClick}
            onKeyDown={onKeyDown}
          >
            <Arrow />
          </button>
        )}
      </div>
      <div className="DatePicker__monthContainer">
        {months.map((m) => (
          <MonthButton
            applyNewFilterUI={applyNewFilterUI}
            hoverIdx={hoverIdx}
            key={m.date.valueOf()}
            monthData={m}
            onMonthClick={onMonthClick}
            onMonthHover={onMonthHover}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
          />
        ))}
      </div>
    </div>
  );
};

export default YearGroup;
