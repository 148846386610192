import FormCheck from '@/components/elements/FormCheck';
import FormGroup from '@/components/elements/FormGroup';
import { selectAccessible } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectIsAccessibleCabinAvailable } from '@/ducks/pages/chooseVoyage/selectors';
import { FormattedMessage } from '@/helpers/formatted-message';
import { useAppSelector } from '@/store';

const onAccessibleChange = (event) => {
  const { checked } = event.target;
  setFilter.accessible(checked);
};

const AccessibilityRefinement = () => {
  const accessible = useAppSelector(selectAccessible);
  const isAccessibleDisabled = useAppSelector(selectIsAccessibleCabinAvailable);

  const isCheckboxDisabled = isAccessibleDisabled || accessible;

  return (
    <form className="AccessibilityRefinement__packages" id="AccessibilityRefinementPackages">
      <FormGroup tabIndex={-1}>
        <FormCheck
          checked={accessible}
          disabled={!isCheckboxDisabled}
          id="accesscabin"
          inputTabIndex={0}
          onChange={onAccessibleChange}
          role="presentation"
          tabIndex={-1}
          type="checkbox"
        >
          <FormattedMessage
            defaultMessage="I need an Accessible Cabin"
            id="AdvancedRefinement.AccessibleCabin.checkbox"
          />
        </FormCheck>
      </FormGroup>
    </form>
  );
};

export default AccessibilityRefinement;
