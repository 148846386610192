import cn from 'classnames';

import Tick from '@/components/Icon/Tick';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import './BoxCheckbox.scss';

type Props = {
  checked?: boolean;
  name?: string;
  onBoxClick?: () => void;
};

const BoxCheckbox = ({ checked, name, onBoxClick }: Props) => {
  const classes = cn('BoxCheckbox', {
    checked,
  });

  const onKeyDownTick = useOnKeyDown(onBoxClick, { isDefaultPrevented: true });

  return (
    <div className={classes} onClick={onBoxClick} onKeyDown={onKeyDownTick} role="button" tabIndex={0}>
      {checked && (
        <span>
          <Tick />
        </span>
      )}
      <span>{name}</span>
    </div>
  );
};

export default BoxCheckbox;
