import { useEffect, useRef, useState } from 'react';

import useGetRefinementPopoverProps from '@/components/ResultRefinements/RefinementPopover/useGetRefinementPopoverProps';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { resetFilters, setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY } from '@/ducks/filters/types';
import { getDefaultFilters } from '@/ducks/filtersOptions';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { type TDateRange } from '@/infra/types/common';
import { useAppSelector } from '@/store';

import DateRefinement from './index';

export type TOnCompleteArgs = TDateRange & { isReset?: boolean };

type UseDateRefinementPopoverProps = {
  isMobile: boolean;
  onChangeStatus: (isOpen: boolean) => void;
};

const useDateRefinementPopover = ({ isMobile, onChangeStatus }: UseDateRefinementPopoverProps) => {
  const disabled = useAppSelector(selectMgm).isReferredBooker;
  const startDate = useAppSelector(selectFromDate);
  const endDate = useAppSelector(selectToDate);
  const defaultFilters = useAppSelector(getDefaultFilters);
  const defaultDateRange = { endDate: defaultFilters.toDate, startDate: defaultFilters.fromDate };
  const [dateRange, setDateRange] = useState<TDateRange>({ endDate, startDate });
  const buttonRef = useRef<HTMLElement | null>(null);

  useEffect(() => setDateRange({ endDate, startDate }), [endDate, startDate]);

  const popoverProps = useGetRefinementPopoverProps({
    buttonRef,
    focusTrapDisableEvents: ['x-arrows', 'y-arrows'],
    isInline: isMobile,
    isMobile,
    onChangeStatus,
    placement: 'bottom-end',
  });

  const onComplete = ({ endDate, isReset, startDate }: TOnCompleteArgs) => {
    const filters = {
      [FILTER_KEY.dateFrom]: startDate,
      [FILTER_KEY.dateTo]: endDate,
      ...(isReset ? { [FILTER_KEY.voyageIds]: null } : undefined),
    };
    setFilters(filters);
    setDateRange({ endDate, startDate });
  };

  const onDateRangeReset = () => {
    resetFilters([FILTER_KEY.dateFrom, FILTER_KEY.dateTo]);
    setDateRange(defaultDateRange);
  };

  return {
    ...popoverProps,
    buttonProps: popoverProps.getReferenceProps(/* { className: ... } */),
    dateRange,
    disabled,
    onDateRangeReset,
    popover: (
      <DateRefinement isMobile={isMobile} key="DateRefinementKey" onComplete={onComplete} popoverProps={popoverProps} />
    ),
  };
};

export default useDateRefinementPopover;
