import React from 'react';

import CaretRightImg from '@publicImages/svg/caret-right.svg?static';

const CaretRight = () => (
  <span className="Icon CaretRight">
    <CaretRightImg />
  </span>
);

export default CaretRight;
