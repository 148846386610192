import { useCallback } from 'react';

import { useBoolean } from 'usehooks-ts';

const DEFAULT_DELAY = 500;

const useActionStatus = <A extends unknown[]>(action?: (...args: A) => void) => {
  const { setFalse: closeStatus, setTrue: show, value: isStatusShown } = useBoolean();

  const actionWithStatus = useCallback(
    (...args: A) => {
      show(); // Show status before executing action

      // Wait briefly to allow status message to be announced
      setTimeout(() => action?.(...args), DEFAULT_DELAY);
    },
    [action],
  );

  return { actionWithStatus, closeStatus, isStatusShown };
};

export default useActionStatus;
